<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div>
    <Swiper class="-m-4" :show-arrows="false">
      <template #items>
        <template v-for="article in block.articles" :key="article.id">
          <div
            class="w-1/2 shrink-0 p-2 md:w-1/3 [&:nth-child(2n+1)]:snap-start md:[&:nth-child(2n+1)]:snap-align-none md:[&:nth-child(3n+1)]:snap-start"
          >
            <div
              class="flex h-full shrink-0 flex-col whitespace-normal rounded-lg bg-white"
            >
              <NuxtLink
                class="p-3 md:p-4 md:pb-6"
                :to="
                  localePath({
                    name: 'articles-slug',
                    params: { slug: article.slug },
                  })
                "
              >
                <NuxtImg
                  v-if="article?.image?.[0]?.url"
                  width="440"
                  height="295"
                  class="object-cover"
                  loading="lazy"
                  :src="article?.image?.[0]?.url"
                  :alt="article?.title"
                />
              </NuxtLink>
              <div
                v-if="article.articleCategory.length > 0"
                class="px-3 md:px-4"
              >
                <NuxtLink
                  :to="article.articleCategory?.[0]?.url"
                  class="inline-block rounded-2xl bg-background-black/5 px-3 py-0.5 text-xs hover:bg-black/10"
                >
                  {{ article.articleCategory?.[0]?.title }}
                </NuxtLink>
              </div>

              <NuxtLink
                class="h-full p-3 md:p-4 md:py-5"
                :to="
                  localePath({
                    name: 'articles-slug',
                    params: { slug: article.slug },
                  })
                "
              >
                <h3 class="mb-3 font-sans text-md hover:underline md:text-lg">
                  {{ article?.title }}
                </h3>

                <template
                  v-for="author in article.articleAuthors"
                  :key="author.id"
                >
                  <p class="text-sm text-supporting-black-87">
                    {{ author?.title }},
                    {{ $t(author?.jobTitleTranslateString) }}
                  </p>
                </template>
              </NuxtLink>
            </div>
          </div>
        </template>
      </template>
    </Swiper>
  </div>
</template>
<script setup lang="ts">
defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});
const localePath = useLocalePath();
</script>
